@import "../color.scss";
@import "../Mixins.scss";
.createSnapShotVolumes {
  &_xIcon {
    font-size: 15px;
    font-weight: 700;
  }
  &_requiredIcon
  {
    color: $red;
  }
  &_snapshotDescTxt
  {
    font-weight: 600;
  }
  &_pleaseProvideTxt
  {
    font-size: 10px;
  }
  &_tagTargetTxt,&_tagsSelected
  {
    font-size: 14px;
  }
  &_addTagsBtn
  {
    max-height: 2.7rem;
    display: flex;
    align-items: center;
  }

}
