@import "../../index.scss";
.inventorycard {
  &_shadow {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  &_mainIcon {
    width: 1.8rem;
  }
  &_subIcons {
    width: 18px;
    &_notificationIcon {
      width: 16px;
      margin-top: 5px;
    }
  }
  &_awsIcon {
    max-width: 30px;
    margin-right: 5px;
  }
  .manage-card {
    min-width: 100%;
    min-height: 19.1rem;
    padding: 0px;
    .title {
      text-align: left;
      padding: 10px 10px;
      font-weight: 600;
    }
    .displaycomponents {
      color: $disabled_label;
      &_Dougnut {
        color: $disabled_label;
        margin-bottom: 2.1rem;
      
      }
    }
  }
  @media (max-width: 768px) {
    .manage-card {
      width: 100%;
    }
  }
}
.smallInventory {
  position: relative;
  .awsCard {
    min-height: 9rem;
  }
  .governanceIcon {
    //height for image

    height: 2rem;
  }
  .awsIcon {
    //height for image
    height: 4.5rem;
  }
  .manage-card {
    min-width: 100%;
    width: max-content;
    min-height: 9rem;
    padding: 0px;
    .title {
      text-align: left;
      padding: 10px 10px;

      font-weight: 600;
    }
    .direction {
      display: flex;
      flex-direction: column;
    }
    .data_style {
      margin-right: 10px;
      font-size: 20px;
      font-weight: bold;
    }
    .subtitle {
      margin-right: 10px;
    }
  }
}
