@import "../color.scss";
.workspace-container {
  &_tableIcons {
    //height for image

    height: 18px;
  }
  .manage-icon {
    //font size for image

    font-size: 18px;
  }
  &_workspaceIDselectable {
    margin-bottom: 0;
    font-size: 15px;
    color: $skyBlue;
    text-decoration: underline;
  }
  &_tableButtons {
    position: relative;
  }
  &_listItems {
    min-width: 15rem !important;
    position: absolute;
    top: 1.8rem;
    z-index: 99999;
    left: 47% !important;
    // @include mediaMax(600px)
    // {
    //     left: 5% !important;
    // }
  }
  .top-container {
    background-color: $table-top-content;
    .top-content {
      font-size: 0.9rem;
      cursor: pointer;
    }
  }
}
.list-group{
  z-index: -100;
}