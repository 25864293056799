  @import "../index.scss";
.createServer {
  &_modalContainer
  {
    max-height: 100%;
  }
  @include mediaMax(1400px)
  {
    &_modalContainer
    {
      max-height: 100vh;
      overflow: auto;
    }
  }
  &_dotIcon {
    width: 23.5px;
  }
  &_secondTab
  {
    max-height: 31rem;
    overflow-y: auto;
  }
  &_requiredStar {
    color: $red;
  }
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $white;
    color: $black;
    padding: 10px;
    border-bottom: 1px solid $light-gray;
  }
  .nav a {
    color: $white;
    text-decoration: none;
    margin-left: 10px;
  }
  .end-content {
    margin-left: auto;
  }
  .side_style {
    min-height: 100vh;
    border-right: 2px solid $disabled_label;
  }
  .head_style {
    margin-top: 1rem;
  }
  .label_style {
    margin-top: 1rem;
  }
  .head_size {
    font-size: larger;
  }
  .custom-column {
    position: relative;
    height: 100%;
  }
  .top-aligned-content {
    position: absolute;
    top: 0;
  }
  .bottom-right-content {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 70vh;
    margin: 10px;
  }
  .bottom-right-contents {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 70vh;
    margin: 10px;
  }
  .btn_style {
    border-radius: 17px;
    font-size: 14px;
    &.btn:disabled {
      opacity: 0.4;
    }
  }
  .previous_btn {
    border-radius: 17px;
    margin-right: 10px;
    background-color: $disabled_label;
    color: $black;
    font-size: 14px;
    border: 1px solid $disabled_label;
  }
  .create_btn {
    border-radius: 17px;
    background-color: $green;
    border: 1px solid $green;
    font-size: 14px;
  }
  .details {
    background-color: transparent;
    color: $black;
    border: transparent;
    margin-top: 2rem;
    &.active {
      color: $blue;
      .span_style {
        border-color: $blue;
        color: $white;
        background-color: $blue;
      }
    }
    &.btn:focus-visible {
      box-shadow: none;
    }
  }
  .selected {
    background-color: transparent;
    color: $black;
    border: transparent;
    margin-top: 1rem;
    font-size: 14px;
    &.active {
      color: $blue;
      .span_style {
        border-color: $blue;
        color: $white;
        background-color: $blue;
      }
      &.btn:focus-visible {
        box-shadow: none;
      }
    }
  }
  .span_style {
    border: 1px solid $disabled_label;
    border-radius: 100%;
    padding: 5px 10px 5px 10px;
    margin-right: 5px;
  }
  @media screen and (min-width: 0px) and (max-width: 760px) {
    .details {
      margin-top: 1rem;
    }
    .side_style {
      min-height: 0;
    }
    .bottom-right-content {
      top: 0;
      
    }
    .bottom-right-contents {
      top: 0;
      margin: 15rem 0 0 0;
    }
  }
}
