@import "../index.scss";
.images-container {
  .Select__menu-list {
    max-height: 120px !important;
  }
  .form-select {
    max-height: 5rem !important;
  }
  &_regionDropdown {
    min-width: 5rem;
    &_regionBody {
      max-height: 25rem;
    }
  }
  &_tableIcons {
    //height for image
    width: 18px;
  }
  .top-container {
    background-color: $table-top-content;
    .top-content {
      font-size: 0.9rem;
      cursor: pointer;
    }
  }
}
