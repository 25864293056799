@import "../../../style/index.scss";
.breadCrumb {
  &_parentHeader {
    color: $lightBlue;
    font-size: 18px;
  }
  &_childHeader {
    font-size: 18px;
    word-wrap: break-word;
  }
  @include mediaMax(400px) {
    &_childHeader {
      font-size: 18px;
      word-wrap: break-word;
      max-width: 14rem;
    }
  }
}
