@import '../color.scss';
.inventoryTab {
  position: relative;
  &_viewByDropdown {
    border: solid 1px $server-bar-color-one !important;
    &_dropdownBtn {
      border-right: solid 0.5px $black !important;
      min-width: 14rem;
      text-align: left;
      font-weight: 200;
    }
  }
  &_cloudTxt {
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 0.2rem;
    margin-bottom: 0;
    color: $header_infra_txt;
  }
  &_viewByTxt {
    font-size: 0.9rem;
    font-weight: 600;
    margin-top: 0.5rem;
    color: $black;
  }
  &_top10ChartContainer {
    max-height: 16.2rem !important;
    &::-webkit-scrollbar {
      max-width: 4px;
      height: 8px; /* Height of the scrollbar */
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $light-grey;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $grey;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: lighten($color: $grey, $amount: 10);
    }
  }
  &_cardIcons {
    max-height: 25px;
    margin-right: 5px;
  }
}
.inventoryPage {
  overflow-x: hidden;
  padding-right: 2rem !important;
  .donughtChartContainer {
    min-width: 25rem;
  }
  .awsCardContainer {
    min-width: 18rem;
  }
  .topTenResourceContainer {
    min-width: 22rem;
  }
  .dropdown {
    border: 1px solid $disabled_label;
    border-radius: 5px;
  }
  .dropdownbordr {
    border: none;
  }
  .inventoryDoughnt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-height: 16.2rem;
  }
  &_viewAllDropdown {
    border: none;
  }
}
