@import "../color.scss";
.notebook {
  &_container {
    width: 95%;
  }
  .manage-icon {
    //font size for image
    font-size: 18px;
  }
  &_tableIcons {
    width: 18px;
  }
  .top-container {
    background-color: $table-top-content;
    .top-content {
      font-size: 0.9rem;
      cursor: pointer;
    }
  }
}
