@import "../../../../style/index.scss";
.cxoTable {
    
  width: 100%;
  overflow-x: auto;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  .subHeaderContainer {
    border-top: 1px solid $dropdown;
  }
  th,
  td {
    padding: 8px;
    border: 1px solid $dropdown;
    text-align: left;
    max-width: 5rem;
    color: $black;
  }
  td {
    width: 3rem;
    color: $black;
    white-space: nowrap;
    overflow: auto;
    text-overflow: ellipsis;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  th {
    color: $black;
    background-color: $white;
    position: relative; 
  }
  th span {
    display: block;
    color: $black;
    padding-top: 10px;
    font-weight: bold; 
  }
  .th-divider {
    position: absolute;
    color: $black;
    top: 0;
    bottom: 0;
    border-left: 1px solid $dropdown; 
  }
  .th-divider:first-child {
    left: calc(33.33% - 1px); 
  }
  .th-divider:nth-child(2) {
    left: calc(66.67% - 1px); 
  }
  th span.sub-header {
    color: $black;
    font-weight: normal; 
    font-size: 12px;
    font-style: italic;
    margin-left: 10px; 
  }
}
