@import '../color.scss';

.inventorySearch {
  .refreshIcon {
    //font size for image
    font-size: 18px;
  }

  &_customBtn {
    // border: solid darken($color: $light-gray, $amount: 5) 1px;
    border: 1px solid $table-header-button-bg;
    background-color: $white;
    max-height: 2.3rem;
    border-radius: 4px !important;
    &:hover {
      border: solid $black 1px;
    }
  }
  &_checkboxDropdown {
    .form-select {
      border-left: transparent;
      border-right: transparent;
      border-radius: 0px;
      background-image: url('../../Images/downArrowFilled.svg') !important;
      background-size: 8px;
    }
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding-right: 25px !important;
    text-overflow: inherit !important;
  }

  .input-group-text {
    height: 38px;
    background-color: $white;
    border-right: transparent;
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &_dropdownsContainer {
    display: flex;
    min-height: 2rem;
  }

  &_filterIconBtn {
    border-top: 1px solid $light-gray;
    border-bottom: 1px solid $light-gray;
    border-left: 1px solid $light-gray;
  }

  &_filterIcon {
    width: 15px;
  }

  .text-two {
    height: 35.8px;
    background-color: $white;
    border-radius: 5px;
    border: 1px solid $disabled_label;
  }

  .search_input {
    border-left: transparent;
    border-right: transparent;

    &.form-control:focus {
      border-color: $dropdown;
      outline: 0;
      box-shadow: none;
    }
  }

  .checkbox_style {
    min-width: 6rem;
    max-width: 7rem;
    font-weight: 600;
  }

  .special_checkbox {
    min-width: 6rem;
    max-width: 12rem;
    font-weight: 600;
  }

  .without_border {
    border: 0 !important;
  }

  .pading_tyle {
    padding: 0px 10px 0px 3px;
    max-height: 40px !important;
  }

  .internal_padding {
    padding: 0px 7px 3px 0px;
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 7px;
    padding-right: 20px !important;
  }
}
