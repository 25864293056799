@import '../color.scss';
.manage-card{
   border: none;
    min-width: 27%;
    min-height: 10%;
    .title{
        text-align: left;
        padding: 10px 10px;
        
        font-weight: 600;
    }
    @media (max-width: 768px) {
        
        display: flex;
        justify-content: space-around;
        width: 60%;
      }
      @media (max-width: 480px) {
        
        width: 100%;
      }
}