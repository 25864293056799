@mixin media($minWidth,$maxWidth)
{
    @media (min-width: $minWidth) and (max-width:$maxWidth)
    {
        @content;
    }
}
@mixin mediaMax($maxWidth)
{
    @media (max-width:$maxWidth)
    {
        @content;
    }
}
@mixin mediaMin($minWidth)
{
    @media (min-width:$minWidth)
    {
        @content;
    }
}

