@import "./color.scss";

.d-none{
  display: none;
}
.headerBox {
  width: max-content !important;
  border: solid $blue 1px;
  display: flex;
  gap: 5px;
  padding: 0px !important;
  align-items: center;
  min-width: 10rem;
  min-height: 2rem;
  justify-content: space-evenly;
  padding: 4px, 4px, 4px, 4px !important;

  p {
    color: $skyBlue;
    font-size: 12px;
    font-weight: bolder;
    margin: 0px !important;
    padding: 0px !important;
  }
}

.headerBoxIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: max-content !important;
  border: solid $skyBlue 1px;
  width: 2.3rem !important;
  height: 2.15rem !important;

  img {
    width: 15px;
  }
}

$opacity-values: 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1;

@each $opacity in $opacity-values {
  .opacity-#{$opacity * 10} {
    opacity: $opacity;
  }
}

.listStyleType-none {
  list-style-type: none;
}

.cursor-pointer {
  cursor: pointer;
}

.error-msg {
  color: $red;
  font-size: 12px;
  margin: 0;
}

.loaderDiv {
  height: 100% !important;
  min-height: 17rem;
  display: flex;
  align-items: center;
}

.cardShadow {
  box-shadow: 0 4px 8px 0 rgba(177, 174, 174, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.chartWrapper {
  width: 100% !important;
  height: 400px !important;
}

//infra
.requiredFieldStar {
  color: $red;
}

.errorMessage {
  color: $red;
  font-weight: 500;
  font-size: 13px;
}

.tableButtonIcons {
  width: 15px;
}

.w-max
{
  width: max-content;
}

.fs-18{
  font-size: 18px;
}

.fs-15{
  font-size: 15px;
}

.fs-20{
  font-size: 20px;
}

.h-max
{
  height: max-content;
}