@import '../../index.scss';
.mainHeader {
  height: 9.3vh;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 100%;
  &_toggleIcon {
      transform: rotate(180deg);
  }
  .userContainer{
   border: solid $skyBlue 2px;
   border-radius: 5px;
   padding: 2px 5px;
    img{
      width: 24.6px;
    }
    p{
      color: $skyBlue;
      margin-bottom: 0;
      font-weight: 500;
    }
  }
}
