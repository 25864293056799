@import "../../../style/index.scss";
.createUser {
  &_passwordInputWrapper {
    position: relative;
    &_eyeBtn {
      position: absolute !important;
      right: 5px;
      top: 6.5px;
      width: 35px;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 350px;
    background-color: $white;
    margin: 0;
    padding: 0.25em;
    box-sizing: border-box;
    border-radius: 0.5em;
    font-size: 16px;
    font-weight: 400;
  }

  .form .Group {
    margin-bottom: 2em;
  }

  .form label {
    margin: 0 0 10px;
    display: block;
    font-size: 1.25em;
    color: $skyBlue;
    font-weight: 600;
  }

  .form input {
    padding: 0.3em 0.5em 0.4em;
    background-color: $light-red;
    border: solid 2px $skyBlue;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    font-size: 1.3em;
    color: #353538;
    font-weight: 600;
    transition: box-shadow 0.2s linear, border-color 0.25s ease-out;
  }

  .form input:focus {
    outline: none;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    background-color: $light-red;
    border: solid 2px $skyBlue;
  }

  .form input::placeholder {
    color: #21719383;
  }

  .form .btn {
    margin: 0;
    padding: 0.5em;
    background-color: $skyBlue;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: none;
    width: 100%;
    height: 50px;
    font-size: 1.4em;
    color: #fff;
    font-weight: 600;
    transition: transforme 0.1s ease-in-out, background-color 0.2s ease-out;
  }

  .form .btn:hover {
    cursor: pointer;
    background-color: $skyBlue;
  }

  .form .btn:active {
    transform: scale(0.98);
  }
}
