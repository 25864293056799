@import "../color.scss";

.server_group {
  .tableIcons {
    //height for image

    height: 18px;
  }
  #search {
    height: 40px;
    background-color: $white;
    border-right: transparent;
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  #refresh {
    border-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: transparent;
    padding: 11px;
  }
  .search_input {
    border-left: transparent;
    border-right: transparent;
    font-size: 1.1rem !important;
    &.form-control:focus {
      border-color: $dropdown;
      outline: 0;
      box-shadow: none;
      font-size: 1.1rem !important;
    }
  }
  .createServer {
    background-color: $header_border;
    border: 1px solid $header_border;
    border-radius: 20px;
  }
  .pagination {
    --bs-pagination-color: $black;
    --bs-pagination-border-radius: 0px;
    --bs-pagination-active-color: $white;
  }
  .page-item.active .page-link {
    z-index: 3;
    color: $white;
    background-color: $header_border !important;
    border-color: $header_border !important;
  }
}
