@import "../index.scss";
.snapshot {
  &_container {
    width: 96%;
  }
  &_tableIcons {
    //height for image

    height: 18px;
  }
}
