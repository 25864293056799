.doughnut{
    
    width: 70%;
    @media (max-width: 768px) {
        // Adjust the width for smaller devices
        width: 70%;
      }
    
      @media (max-width: 480px) {
        // Further adjust the width for even smaller devices
        width: 100%;
      }
 
}