@import '../index.scss';
.infraTxt
{
    font-size: 1.1rem;
    font-weight: 600;
    color: $header_infra_txt;
}
.header {
    .nav {
        background-color: $white;
        color: $black;
    }
    a {
        color: $black;
        font-weight: 600;
        
        &.active {
            background-color: transparent;
            border-color: transparent;
            color: $header_border;
        }
        &:focus {
            outline: 0;
            box-shadow: none;
        }
        &:hover {
            background-color: transparent;
            color: $header_border;
        }
    }
}