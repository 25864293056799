@import '../color.scss';

.environment{
    .review{
        .heading{
            background-color: $white;
            padding: 7px 5px 18px 14px;
            border: 1px solid $disabled_label;
        }
        .padding_style{
            padding: 0;
        }
        .table_style{
            border: 1px solid;
        }
        .table td, .table th {
            border: none;
        }
        
        table.table.table-bordered {
            border: 1px solid $disabled_label;
        }
        .row_style{
            font-weight: 300;
            font-size: 14px;
            text-align: left;
            
        }
        td{
            text-align: left;
        }
    }
    .permission{
        .custom-switch {
            display: flex;
            align-items: center;
          }
          
          .left-label {
            margin-right: 10px;
          }
          
          .right-label {
            margin-left: 10px;
          }
          .p_style{
            font-size: 14px;
            margin-top: 2rem;
            margin-left: 1rem;
          }
          .role_style{
            margin-top: 1rem;
          }
          .switch_style{
            font-size: 14px;
          }
    }
    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color:$white;
        color:$black;
        padding: 10px;
        border-bottom: 1px solid $light-gray;
        }
        
        .nav a {
          color:$white;
          text-decoration: none;
          margin-left: 10px;
        }
        
        .end-content {
          margin-left: auto;
        }
  
       .side_style{
          min-height: 100vh;
          border-right: 2px solid $disabled_label;
          &.btn , .btn:active{
                color: $blue !important;
                background-color: transparent !important;
                border-color: $accordionDisabled !important;
          }
          &.btn, .btn:focus{
            border: transparent;
            box-shadow: none;
          }
       }
        .head_style{
          margin-top: 1rem;
        }
      .label_style{
          margin-top: 1rem;
  
      }
      .head_size{
        font-size: larger;
      }
      .custom-column {
        position: relative;
        height: 100%;
      }
      
      .top-aligned-content {
        position: absolute;
        top: 0;
      }
      
      .bottom-right-content {
        position: absolute;
        bottom: 0;
        right: 0;
        top: 70vh;
        margin: 10px; 
      }
      
      .bottom-right-contents {
        position: absolute;
        bottom: 0;
        right: 0;
        top: 70vh;
        margin: 10px; 
      }
      .btn_style{
        border-radius: 17px;
        font-size: 14px;
        &.btn:disabled{
          opacity: 0.4;
        }
      
      }
      .previous_btn{
        border-radius: 17px;
        margin-right: 10px;
        background-color: $disabled_label;
        color: $black;
        font-size: 14px;
        border: 1px solid $disabled_label;
        &.btn , .btn:active{
            color: $black !important;
            background-color: $disabled_label !important;
            border-color: $disabled_label !important;
      }
      &.btn, .btn:focus{
        border: transparent;
        box-shadow: none;
      }
      }
      .create_btn{
        border-radius: 17px;
        background-color: green;
        border: 1px solid green;
        font-size: 14px;
        &.btn , .btn:active{
            color: $white !important;
            background-color: green !important;
            border-color: green !important;
      }
      &.btn, .btn:focus{
        border: transparent;
        box-shadow: green;
      }
      }
      .details{
        background-color: transparent;
        color: $black;
        border: transparent;
        margin-top: 2rem;
        font-size: 14px;
        
        &.active{
          color: $blue;
          .span_style{
            border-color:$blue;
            color: $white;
            background-color: $blue;
          }
        }
        &.btn:focus-visible {
          box-shadow: none;
        }
      }
      .selected{
        background-color: transparent;
        color: $black;
        border: transparent;
        margin-top: 1rem;
        font-size: 14px;
        &.active{
          color: $blue;
          .span_style{
            border-color:$blue;
            color: $white;
            background-color: $blue;
            
          }
          &.btn:focus-visible {
            box-shadow: none;
          }
        }
      }
      .span_style{
        border: 1px solid $disabled_label;
        border-radius: 100%;
        padding: 5px 10px 5px 10px;
        margin-right: 5px;
        
      }
  
      @media screen and (min-width:0px) and (max-width : 760px) {
        .details{
          margin-top: 1rem;
        }
        .side_style{
          min-height: 0;
        }
        .bottom-right-content{
          top: 0;
          // margin-top: 5px;
        }
        .bottom-right-contents{
          top: 0;
          margin: 15rem 0 0 0;
        }
      }
    
}