@import "../color.scss";
.serverGroupCard {
  .manage-card {
    min-width: 100%;
    height: 12rem;
    padding: 0px;
    .title {
      text-align: left;
      padding: 10px 10px;
      font-weight: 550;
    }
  }
  .delete {
    display: flex;
    justify-content: flex-end;
    padding: 7px 7px 0px 0px;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
  }
  .titles {
    display: flex;
    justify-content: flex-start;
    padding: 0px 0px 0px 20px;
    max-width: 80%;
  }
  .subdata {
    display: flex;
    justify-content: flex-start;
    padding: 10px 0px 0px 20px;
    font-size: 13px;
    color: $disabled_label;
  }
  .instant {
    display: flex;
    justify-content: flex-start;
    padding-left: 19px;
  }
}
