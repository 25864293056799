@import "style/index.scss";
.App {
  height: 88.4vh;
  overflow-y: auto;
  width: 100vw;
}
.AppCXO {
  height: 100vh;
  overflow-y: auto;
}
.AppLogin {
  height: 100vh;
  overflow-y: auto;
  width: 100vw;
}
.AppHamburgerIconContainer {
  position: sticky;
  left: 0%;
  top: 1.6rem;
  background-color: $white;
  border: solid $skyBlue 2px;
  z-index: 9999;
  border-left: none;
  cursor: pointer;
  width: 1rem;
}
.Layout {
  @media (min-width: 768px) {
    &.minimised {
      & .Layout__wrapper {
        padding-left: 4.5rem;
        .mainHeader {
          width: 100%;
        } // max-width: calc(100% - 2rem);
        .App {
          height: 88.4vh;
          overflow-y: auto;
          width: 100%;
        }
        .mainHeader_toggleIcon {
          display: none;
        }
      }
      & .headerHidden {
        padding-left: 4.5rem;
        .App {
          height: 100vh;
          overflow-y: auto;
          width: 100%;
        }
        .mainHeader_toggleIcon {
          display: none;
        }
      }
    }
    &.maximised {
      & .Layout__wrapper {
        padding-left: 17em;
        .mainHeader {
          width: 100%;
        }
        .App,
        .AppLogin {
          height: 100vh;
          overflow-y: auto;
          width: 100%;
        }
        .mainHeader_toggleIcon {
          display: none;
        }
      }
      & .Layout__wrapper {
        padding-left: 17em;
        .mainHeader {
          width: 100%;
        }
        .AppCXO,
        .AppLogin {
          height: 100vh;
          overflow-y: auto;
          width: 100%;
        }
        .App {
          height: 88.4vh;
          overflow-y: auto;
          width: 100%;
        }
        .mainHeader_toggleIcon {
          display: none;
        }
      }
    }
  }
}
