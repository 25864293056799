@import "../index.scss";
.server-container {
  &_instanceId {
    width: 10rem;
    word-wrap: break-word;
    color: $header_border;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-shadow: 0 0.25px 0 $dropdown;
    }
  }
  &_dotIcon {
    width: 23.5px;
  }
  &_listItems {
    min-width: 15rem !important;
    position: absolute;
    bottom: 2.5rem;
    left: 50% !important;
  }
  &_tableButtons {
    position: relative;
  }
  &_tableIcons {
    //height for image

    height: 18px;
  }
  .right-title {
    font-weight: 600;
    color: $black;
    margin-left: 4px;
    font-size: 0.8rem;
  }
  .manage-icon {
    padding-left: 5px;
    color: $black;
  }
  .card-container {
    display: flex;
    justify-content: start;
    gap: 2.5rem;
    margin-left: 2rem;
    .manage-doughnut {
      width: 80%;
    }
    .manage-pie {
      width: 80%;
    }
  }
  @media screen and (max-width: 1000px) {
    .card-container {
      display: flex;
      flex-direction: column !important;
    }
  }

  @media screen and (max-width: 768px) {
    .card-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      align-items: center;
    }
  }
  .oprating-system {
    min-width: 47.8%;
    .title {
      text-align: left;
      padding: 10px 10px;
      font-weight: 550;
    }
  }
  @media (max-width: 768px) {
    .oprating-system {
      display: flex;
      justify-content: space-evenly;
      width: 60%;
    }
  }
  @media (max-width: 480px) {
    .oprating-system {
      width: 100%;
    }
  }
  .top-container {
    background-color: $table-top-content;
    .top-content {
      font-size: 0.9rem;
      cursor: pointer;
    }
  }
}
.Server_Graph_hide {
  height: 300px;
  display: flex;
  align-items: center;
}
