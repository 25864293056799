.SidebarOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 500;
  animation: offcanvas-fade-in 0.6s ease 1; // ANIM in animation.scss
  z-index: 999;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
}
