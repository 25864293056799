@import "../../color.scss";

.server_bar_chart {
  min-width: 28rem;
  @media (max-width: 768px) {
    .server_bar_chart {
      width: 100% !important;
    }
  }

  @media (max-width: 480px) {
    .server_bar_chart {
      width: 100% !important;
    }
  }
}

.bar-chart {
  min-width: 100%;
  @media (max-width: 768px) {
    .bar-chart {
      width: 100%;
    }
  }

  @media (max-width: 480px) {
    .bar-chart {
      width: 100%;
    }
  }
}
