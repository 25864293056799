@import "../index.scss";
.shareModal {
  &_xIcon {
    font-size: 15px;
    font-weight: 700;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
