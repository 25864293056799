.gYDGIN {
  display: none !important;
}
 
.infraTable {
  .rdt_TableRow:nth-child(1) {
    .rdt_TableCell:nth-child(1) {
      input {
        display: none;
      }
    }
  }
}

.infraTableHideFirstAndSecondField {
  .rdt_TableCol:nth-child(1) {
    display: none;
  }

  .rdt_TableRow:nth-child(1) {
    .rdt_TableCell:nth-child(1) {
      display: none;
    }
  }
}
 
//need this for responsiveness
.commonTable {
  max-width: 90vw;
}
