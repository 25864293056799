@import "../../index.scss";

.pageBtn {
  color: $red;
  background: $white 0% 0% no-repeat padding-box;
  border: 0.5px solid $skyBlue;
  border-radius: 6px;
  min-width: 30px;
  max-width: min-content;
  height: 30px;
  margin: 0px 10px;
}

.currentPage-1 {
  color: $darkgrey;
}

.currentPage {
  color: $white;
  background: $skyBlue;
  border: 1px solid $skyBlue;
}

.notCurrentPage {
  color: $skyBlue;
  background: $white;
  border: 1px solid $skyBlue;
}

.currentPage-not1 {
  color: $skyBlue;
}

.pagination {
  &-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 3px 10px;
  }

  @include mediaMax(550px) {
    &-wrapper {
      margin-bottom: 5rem;
    }
  }

  &-select {
    .CreateRule_RuleScope_Dropdown {
      box-shadow: none;
      min-width: 4rem;
      border: solid $skyBlue 0.5px !important;
      color: $skyBlue !important;
    }
  }

  &-Icons {
    img {
      width: 28px;
      cursor: pointer;
    }
  }
}

.table-wrapper {
  overflow-y: auto;
  width: 100%;
  max-width: 100%;
  margin-top: 10;

}

.custom-table {
  margin-top: 0 !important;

  .verticalScrollWrapper {
    overflow: auto;
    max-width: 93vw;
    max-height: 74vh;
    margin-bottom: 11px;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px $grey;
      background-color: $white;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 7px;
      background-color: $white;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $navyBlue;
      font-size: 12px;
    }
  }

  table {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 0px;

    thead {
      background: $skyBlue !important;
      color: $white;

      th {
        padding-left: 1.5rem;
        text-align: left !important;
        font-weight: 400;
        max-width: 17rem;
        word-wrap: break-word;
        background: $navyBlue;
        color: $white;
        font-size: 10px;
        vertical-align: middle;
        border: none;
        font-size: 12px;
      }
    }

    tr {
      vertical-align: middle;
    }

    td {
      padding-left: 1.5rem;
      text-align: left;
      min-width: 100px;
      max-width: 17rem;
      word-wrap: break-word;
      font-size: 12px;

      background: $white;
      border: none;
    }

    th {
      min-width: 100px;
    }
  }

  table {
    overflow: hidden;
    border-radius: 0px 10px 0rem 0rem !important;
    margin-bottom: 0px;
    overflow: visible !important;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 600;
    outline: 1px solid $light-grey !important;
  }
}

.custom-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px $grey;
  background-color: $white;
  border-radius: 10px;
}

.custom-table::-webkit-scrollbar {
  width: 5px;
  height: 7px;
  background-color: $white;
}

.custom-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $skyBlue;
  font-size: 12px;
}

.no-record-found {
  position: sticky;
  left: 0px;
}

.fixed-row-right {
  right: 0;
  position: sticky;
  top: auto;
  z-index: 500;
  // width: 125px;
}

.fixed-row-left {
  left: 0;
  position: sticky;
  top: auto;
  z-index: 500;
  // width: 125px;
}

@include mediaMax(425px) {
  .fixed-row-right {
    position: relative;
  }

  .fixed-row-left {
    position: relative;
  }
}

.regularPaymentButtonDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.selectedRow {
  background-color: $selectedRowBgTable !important;
}