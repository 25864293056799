@import "../color.scss";

.serverGroupName {
  position: relative;

  .nav-underline .nav-link.active {
    color: $blue;
    max-height: 2.25rem;
  }
  .nav-underline .nav-link {
    color: $black;
    max-height: 2.25rem;
  }
  .nav-underline .nav-link:hover,
  .nav-underline .nav-link:focus {
    border-bottom-color: none;
  }
  &_dotIcon {
    width: 23.5px;
  }
  .commonTable {
    max-width: 94vw;
  }
  &_tableIcons {
    //height for image

    height: 18px;
  }
  & .nav-tabs {
    & .nav-link {
      &.active {
        border-top: 3px solid $header_border;
        border-right: 2px solid $disabled_label;
      }
    }
  }
  .titles {
    color: $header_border;
  }
  .subdata {
    font-size: 13px;
  }
  .header {
    color: $blue !important;
    font-weight: 100;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .subheader {
    color: $black;
    font-weight: 600;
    font-size: 18px;
  }
}
