.deleteModal {
  &_deleteIcon {
    height: 5rem;
  }
  &_deleteTxt {
    margin-top: 2rem;
    font-size: 16px;
  }
  &_list {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
