// @import '../../../../../style/common/common.scss';
@import '../index.scss';
.scanModalDetails{
    .titles{
        color: $header_border;
    }
   &_tableWrapper
   {
    overflow-x: auto;
   }
}