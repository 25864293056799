@import "../color.scss";

.serverDetails {
  position: relative;
  .border {
    box-shadow: 1px solid $black;
  }

  .nav-underline .nav-link.active {
    color: $blue;
    max-height: 2.25rem;
  }
  .nav-underline .nav-link {
    color: $black;
    max-height: 2.25rem;
  }
  .nav-underline .nav-link:hover,
  .nav-underline .nav-link:focus {
     border-bottom-color: none;
  }
  .titles {
    color: $header_border;
  }
  .subdata {
    font-size: 13px;
    margin-top: 15px;
  }
  .server_style {
    font-size: 17px;
    color: blue;
  }
  .server_name {
    font-size: larger;
    font-weight: bold;
  }
}
.details {
  max-height: 48vh;
  overflow-y: auto;
  .table_style {
    border: 1px solid;
  }
  .table td,
  .table th {
    border: none;
  }

  table.table.table-bordered {
    border: 1px solid $disabled_label;
  }
  .row_style {
    font-weight: 300;
    font-size: 14px;
    text-align: left;
  }
  td {
    text-align: left;
  }
}
.monitoring {
  .gridStyle {
    border: 1px solid $dropdown;
    padding: 5px;
  }
  .graph_label {
    font-size: 15px;
    color: $blue;
    display: flex;
    padding: 4px;
  }
}
