.Layout.minimised {
  & .SidebarMenu {
    a {
      text-decoration: none !important;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &__list {
      list-style: none;
      padding: 0;
      &-item {
        &.child {
          list-style: circle !important;
          display: none;
        }
        & .menu-link {
          &-text,
          &-caret {
            display: none;
          }
        }
      }
    }
  }
}

.Layout.maximised,
.Layout.hovered {
  & .SidebarMenu {
    a {
      text-decoration: none !important;
    }
    &__list {
      max-height: 31rem;
      overflow: auto;
      list-style: none;
      padding: 0;
      &-item {
        &.child {
          list-style: circle !important;
          display: block;
        }
        & .menu-link {
          &-text,
          &-caret {
            display: block;
          }
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.SidebarMenu {
  a {
    text-decoration: none !important;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &__list {
    &-item {
      & .menu-link {
        color: var(--sidebar-color);
        transition: var(--main-transition-colors);
        display: block;
        padding: 9px 25px;

        &-icon {
          margin-right: 10px;
          & svg {
            height: 15px;
            width: 15px;
            & path {
              fill: var(--sidebar-color);
            }
          }
        }

        &-text {
          font-size: 13px;
          font-weight: 400;
        }

        & .menu-bullet {
          &-dot {
            > span {
              background-color: var(--sidebar-color);
            }
          }
        }

        & .menu-link-caret {
          transition: all 0.3s ease;
          transform: rotate(0deg);

          &.open {
            transform: rotate(90deg);
          }
        }

        &:hover,
        &.menu-link-active {
          text-decoration: none;
          background-color: var(--sidebar-active-background);
          color: var(--sidebar-active-color);

          & .menu-link-icon {
            & svg {
              & path,
              & rect {
                fill: var(--sidebar-active-color);
              }
            }
          }

          & .menu-bullet {
            &-dot {
              > span {
                background-color: var(--sidebar-active-color);
              }
            }
          }
        }
      }
    }
  }
}

.SidebarMenu.SidebarMenuSmall {
  a {
    text-decoration: none !important;
  }
  .SidebarMenu__list {
    &-item {
      &.child {
        display: block !important;
      }
      & .menu-link {
        &-text,
        &-caret {
          text-decoration: none;
          display: block !important;
        }
      }
    }
  }
}
