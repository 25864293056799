@import '../../index.scss';
.button-container{
    button{
        border: none;
        background-color: $white;
        cursor: pointer;
    }   
    .manage-icon{
        // margin-left: 5px;
        padding-left: 5px;
       color: $table-header-button-bg;
    }
    .title{
        color: $table-header-button-bg;
        margin-left: 4px;
    font-size: 0.8rem;
    }
    
}
