@import "../index.scss";
.subheader {
  &_navLinkTxt {
    font-weight: 600;
  }
  .compute-bar {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    width: 100%;
    a {
      position: relative;
      cursor: pointer;
      letter-spacing: 0.5px;
      padding-bottom: 5px;
      font-weight: 600;
      cursor: pointer;
      font-size: 1rem;
      &::after {
        content: "";
        color: $blue;
        position: absolute;
        transition: 0.3s;
        background-color: $blue;
        height: 2px;
        width: 0;
        left: 0;
        bottom: -1px;
        color: $blue;
      }
      &.active::after {
        width: 100%;
        color: $blue;
      }
    }
    a.active {
      color: $blue;
    }
  }
}
