@import "../../../style/index.scss";
.Auth {
  width: 100%;
  height: 100vh;
  .passwordWrapper {
    position: relative;
    &_passwordIcon {
      position: absolute;
      top: 12px;
      right: 7.5px;
      cursor: pointer;
    }
  }
  &__titlecontainer {
    position: absolute;
    top: 40%;
    left: 5rem;
    transform: translateY(-50%);
    & .title {
      font-size: 2rem;
    }
    & .brand {
      font-size: 5rem;
      font-weight: 600;
      line-height: 100%;
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__form {
    &-wrapper {
      & .form-sublink {
        font-size: 0.9rem;
        font-weight: 600;
      }

      & .outlook-login {
        & svg {
          width: 1.2rem;
        }
      }
    }

    &-loader {
      & .loader {
        font-size: 8px;
      }
    }

    &-message {
      font-size: 17px;
      font-weight: 500;

      &.error {
        color: $red;
      }
    }
  }

  // login ui -prajakta

  &__container {
    width: 100%;
    @media (max-width: 1200px) {
      display: flex;
    }
    @media (min-width: 1200px) {
      display: grid;
      grid-template-columns: 0.55fr 0.45fr;
    }
  }
  .banner-wrapper {
    position: relative;
    background: linear-gradient(180deg, #ffffff, #ffffff, #e2e9ff);

    .banner {
      width: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .title-wrapper {
    font-size: 22px;
    color: $white;
  }

  .svg-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .svg-logo {
    width: 10.313rem;
    height: 8.526rem;
  }
  .outlook-img {
    height: 23px;
  }
  .Auth__form {
    .input {
      border: 1px solid #d1d1d1 !important;
      border-radius: 5px;

      &::placeholder {
        opacity: 1 !important;
        color: $input-border;
      }
    }

    .text-label {
      font-size: 1rem;
      font-weight: 500;
      text-align: left;
    }
    .login-btn {
      border-radius: 5px;
      padding: 11px 8px !important;
      background-color: $theam-color;
    }
    .outlook-btn {
      border-radius: 5px;
      padding: 11px 8px !important;
      background-color: $white;
      border: 1px solid $theam-color;
    }
  }
  .text-blue {
    color: $theam-color;
  }
}
