$white: #fff;
$light-red: #f0f0f0;
$light-blue: #6e7881;
$soft-blue: #7066e0;
$original-red: #d33;
$light-gray: #e6e6e6;
$disabled_label: #abb2b9;
$black: #2e2e2e;
$blue: #5e34f5;
$cxoOrange:#f7981d;
$cxoLightBlue:#3ec29f;
$tab_color: #aed6f1;
$selectedRowBgTable: #d5eeff;
$dropdown: #ced4da;
$header_border: #ff7f50;
$table-top-content: #fafafa;
$priorityMediumLight:#FFEECF;
$priorityMediumDark:#FF9931;
$priorityLowLight:#C5FFE4;
$priorityLowDark:#03B176;
$priorityHighLight:#FFE2E5;
$priorityHighDark:#F64E60;
$dark-blue: #1f0f7b;
$lightBlue: #5175a6;
$skyBlue: #335ccb;
$navyBlue: #253a71;
$cxoBlue:#245d7d;
$hdfbsCardcolor:#1f2e3c;
$light-grey: #f1f3f5;
$loaderLightBlue:#62abff;
$loaderFrontColor:#4f29f0;
$green:green;
$red: red;
$cxoDasboardBlue:#1879B1;
$cxoDashboardOrange:#eb6023;
$grey: grey;
$lighterBlue: #c9e1ff;
$lightGrey2: #dee2e6;
$darkGreen: #00b219;
$darkgrey: #707070;
$doughnut-border: #a2cfd3;
$doughnut-border-mango: #fad99f;
$doughnut-border-dark-cyan: #9eb7cc;
$tab_background: #f4f6f6;
$server-bar-color-one: #335ccb;
$server-bar-color-two: #798892;
$table-header-button-bg: #c7c4c4;
$bar-backfround-color: #66bcfd;
$img_background: #e6f5ff;
$Activate-on-prem-download-button: #51c7a1;
$yellow: #fce300;
$yellowOrange: #ff8f00;
$oliveGreen: #1b5e20;
$darkGrey: #555;
$blueTxt: #1c84c6;
$greenTxt: #0f9d58;
$boxShadowColor: rgba(0, 0, 0, 0.1);
$lightGrey: #cccccc;
$white-bg: #eff5fb;
$bg-white: #f1f1f1;
$basic-black : #000000;
$basic-black-opacity : #00000012;
$theam-color:#2c4894;
$input-border:#D1D1D1;

//infra
$white:#fff;
$light-gray:#e6e6e6;
$disabled_label :#ABB2B9  ;
$black:#000;
$darkBlueGrey:#3c484F;
$blue:#5e34f5;
$tab_color : #AED6F1;
$dropdown :#ced4da; 
$header_border : #335CCB;
$skyBlue: #335ccb;
$accordionDisabled:#0c63e4;
$red:red;
$darkgrey: #707070;
$grey: grey;
$navyBlue: #253a71;
$light-grey: #f1f3f5;
$selectedRowBgTable: #d5eeff;
$checkboxPrimaryColor:#5e34f5;

$header_infra_txt:#253A71;
$table-top-content:#fafafa;
$doughnut-border:#a2cfd3;
$doughnut-border-mango:#fad99f;
$doughnut-border-dark-cyan:#9eb7cc;
$tab_background:#F4F6F6;
$server-bar-color-one:#335CCB;
$server-bar-color-two:#798892;
$table-header-button-bg:#c7c4c4;
$bar-backfround-color:#66bcfd;
$Activate-on-prem-download-button:#51c7a1