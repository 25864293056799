@import '../../index.scss';
.dropdown_style{
    .dropdown-toggle::after {
        display: none !important;
      }
    .form-select{
        border-left: transparent;
        border-right: transparent;
        border-radius: 0px;      
    }
    .form-select:focus {
        border-color: $dropdown;
        outline: 0;
        box-shadow: none;
    }
    .last_dropdown{
        border-right:1px solid $dropdown;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}