@import '../color.scss';
.VolumeModal{
    .btn_place{
        display: flex;
        justify-content: end;
        margin-bottom: 1rem;
        margin-top: 1.5rem;
    }
    .close_style{
        margin-right: 5px;
        background-color: $disabled_label;
        border: 1px solid $disabled_label;
        font-size: 14px;
    }
    .modify_style{
        font-size: 14px;
    }
    .title{
        font-size: 18px;
    }
    .subLables{
        font-size: 14px;
        min-width: 50%;
    }
}
.volume{
    .btn_style{
        border-radius: 20px;
        font-size: 12px;
        padding: 1px 5px 1px 5px;
    }
}
.Modify_volume_Modal{
    overflow: auto;
    max-height: 450px;
}