@import "../../style/index.scss";

.Layout.maximised,
.Layout.hovered {
  .SidebarBig {
    &_signOut {
      height: 25px !important;
      width: max-content !important;
    }
    width: 17em !important;
    z-index: 9999999999999999;
    &__branding {
      background-color: white;
      &-logo {
        width: 5rem;
        display: block !important;
      }
      &-toggle {
        &-icon {
          transform: none !important;
        }
      }
    }

    .logoutBtn {
      button {
        width: 90% !important;
        p {
          display: block !important;
        }
      }
    }
    .userContainer {
      justify-content: start !important;
      color: $white;
      border-radius: 5px;
      width: 90%;
      margin: 5%;
      padding: 2px 5px !important;
      img {
        width: 25px;
      }
      p {
        display: block !important;
        color: $white;
        margin-bottom: 0;
        font-weight: 500;
      }
    }
  }
}

.Layout.minimised,
.Layout.unhovered {
  .SidebarBig {
    width: 5rem;
    // z-index: 9999999999999999;
    &_signOut {
      height: 25px;
      min-width: 2rem;
    }

    .logoutBtn {
      button {
        width: 68%;
        p {
          display: none;
        }
      }
    }
    .userContainer {
      justify-content: center;
      color: $white;
      border-radius: 5px;
      width: 90%;
      margin: 5%;
      padding: 2px;
      img {
        width: 25px;
      }
      p {
        display: none;
      }
    }
    &__branding {
      background-color: white;
      &-logo {
        display: none;
      }
      &-toggle {
        & button {
          padding: 0 !important;
        }
        &-icon {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.SidebarBig,
.SidebarSmall {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: $navyBlue;

  &__branding {
    background-color: $white;
    padding: 0 25px;
    height: 65px !important;

    &-logo {
      font-weight: bold;
      font-size: 22px;
      letter-spacing: 1.5px;
      & a {
        &:hover {
          text-decoration: none;
        }
      }
    }

    &-toggle {
      &-icon {
        transition: all 0.15s ease;
      }
    }
  }
}

.SidebarSmall {
  max-width: 90%;
  right: auto;
  z-index: 1000;
  &_signOut {
    height: 25px;
    width: max-content;
  }

  .logoutBtn {
    button {
      p {
        display: none;
      }
    }
  }
  .userContainer {
    justify-content: center;
    color: $white;
    border-radius: 5px;
    width: 90%;
    margin: 5%;
    padding: 2px 5px;
    img {
      width: 25px;
    }
    p {
      display: none;
    }
  }
  &.open {
    right: auto !important;
    left: 0 !important;
  }
  &.close {
    display: none !important;
  }
}

.SidebarSmallOverlay {
  @media (min-width: 768px) {
    position: unset;
    display: none;
  }
}
.sidebarWrapper {
  position: relative;
}
.logoutBtn {
  width: 100%;
  position: absolute;
  bottom: 1rem;
  button {
    margin: 10px 10px 0px 10px;
    display: inline-block;
    padding: 10px 20px;
    width: 90%;
    background-color: $dark-blue; /* Red background color */
    color: $white; /* White text color */
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    &:hover {
      background-color: lighten(
        $color: $dark-blue,
        $amount: 20
      ); /* Darker red on hover */
    }
    &:active {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }
}
