@import "../index.scss";
.tagSearchModal {
  &_alert {
    background-color: lighten($color: $header_border, $amount: 40);
  }
  &_xIcon {
    font-size: 15px;
    font-weight: 700;
  }
  &_borderBottom {
    border-bottom: solid 1px $black;
  }
  &_addTagBtn {
    cursor: pointer;
    height: 1rem;
    width: max-content;
    padding: 13px;
    display: flex;
    align-items: center;
  }
}
