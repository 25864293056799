@import "../../style/index.scss";
.users {
.MuiTablePagination-selectLabel,.MuiTablePagination-displayedRows
{
    margin-bottom: 0 !important;
}
  &_icons {
    height: 18px;
    width: 18px;
  }
  &_headText {
    color: $lightBlue;
    font-size: 18px;
  }
}
