@import "react-datepicker/dist/react-datepicker.css";
@import "./style/Mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap");
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace ;
}
.appContainer {
  width: 82%;
}
.sidebarContainer {
  width: 18%;
}

@include mediaMax(1180px) {
  .appContainer {
    width: 78%;
  }
  .sidebarContainer {
    width: 22%;
  }
}

@include mediaMax(749px) {
  .sidebarContainer {
    width: 65%;
  }
  .appContainer {
    width: 35%;
  }
}

.cursor-pointer {
  cursor: pointer;
}
