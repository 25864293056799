@import "../index.scss";
.storageHeader {
  text-align: center;
  background-color: $light-gray;
  width: 100%;
  &_tableIcons {
    //height for image
    height: 18px;
  }
  .btn-primary {
    color: $black;
  }
  .btn.active {
    background-color: transparent;
    border-color: transparent;
    color: $blue;
  }
  .btn:focus {
    outline: 0;
    box-shadow: none;
  }
  .btn:hover {
    background-color: transparent;
  }
  Button {
    position: relative;
    color: $black;
    font-size: 1rem;
    &::after {
      content: "";
      color: $blue;
      position: absolute;
      transition: 0.3s;
      background-color: $blue;
      height: 1px;
      width: 0;
      left: 0;
      bottom: 2px;
      color: $blue;
    }
    &.active::after {
      width: 100%;
      color: $blue;
    }
  }
}
.commmon_stye {
  margin: 2%;
  .top-container {
    background-color: $white;
    padding: 12px;
    .top-content {
      font-size: 0.9rem;
      cursor: pointer;
    }
  }
}
