@import '../../index.scss';
.accordion{
    &_wrapper
    {
        max-width: 90vw;
    }
 @include mediaMax(1100px){

    &_wrapper
    {
        max-width: 80vw;
    }
 }
}