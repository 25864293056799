.pie{
 
    width: 70%;
   
    @media (max-width: 768px) {
        // Adjust the width for smaller devices
        .pie{
            width: 70%;
        }
      }
    
      @media (max-width: 480px) {
        .pie{
            width: 100%;
        }
        // Further adjust the width for even smaller devices
        
      }
    
}
