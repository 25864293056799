@import "../../../style/index.scss";
.cxo-dashboard {
  .mainHeader {
    display: none !important;
  }
  .dollar-value {
    font-size: 60px;
    color: $skyBlue;
  }
  .firstLayerServiceData {
    font-size: 60px;
    color: $skyBlue;
  }

  .secondLayerServiceData {
    font-size: 60px;
    color: $cxoLightBlue;
  }
  .negativeCompareStateTxt {
    color: lighten($color: $red, $amount: 15);
  }
  .positiveCompareStateTxt {
    color: lighten($color: $green, $amount: 15);
  }
  .costSavingDecreaseTxt {
    font-size: 17px;
    color: $skyBlue;
  }
  .headFont {
    font-size: 20px;
  }
  .operationalHeadTxt {
    font-size: 60px;
    color: $cxoDashboardOrange;
  }

  .costSavingContainerLeft {
    height: max-content;
    max-height: 47rem;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $white;
    }
  }
  @include mediaMax(767px) {
    .bg-light {
      height: max-content !important;
    }
    .auditCodeContent {
      flex-direction: column-reverse !important;
    }
  }
  .serviceInsightsPoints {
    height: 41rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $white;
    }
  }
  .lastInsightsPoints {
    max-height: 25rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $white;
    }
  }
  .lastInsightsTable {
    max-height: 30rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $white;
    }
  }
  .auditCodeContent {
    padding-left: 1.5rem;
    flex-direction: row;
  }
  .hdfbsDashboard {
    background-color: $light-grey;
    min-height: 700px;
    padding: 30px;
    text-align: center;
    &_divider {
      border-right: solid 1px $black;
    }
  }
  .dasboardCard {
    position: relative;
    width: 100%;
    margin: 10px;
    min-height: 14.5rem;
    height: max-content !important;
    @include mediaMax(400px) {
      min-height: 22rem !important;
    }
    @include media(400px, 600px) {
      min-height: 18rem;
    }
    .height_max {
      height: max-content;
    }
    .tableWrapperInsights {
      height: max-content;
      padding-bottom: 3rem;
    }
    .dashboardCardTableWrapper {
      table {
        width: 90%;
      }

      position: absolute;
      bottom: 0.5rem;
    }
  }

  .hdfbsDashboard-space {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .hdfbsDashboard-space .cxo-item {
    width: 100%;
  }
  .hdfbsDashboard-paragraph {
    color: $white;
    font-size: 10px;
    line-height: 1.5;
    word-wrap: normal;
    padding: 40px;
  }
  .hdfbsCardDashboard {
    background-color: $white !important;
    p {
      color: $cxoBlue;
    }
    .css-46bh2p-MuiCardContent-root:last-child {
      padding-bottom: 0px !important;
    }
    &_numbers {
      font-weight: 900;
      font-size: 2rem;
    }
  }
  .hdfbsCard {
    color: $hdfbsCardcolor;
    background-color: $light-grey;
    margin: 70px;
  }
  .hdfbsCard-img-top {
    width: 100%;
    margin: auto;
  }
  .hdfbsCard-title {
    font-size: 15px;
    margin-top: 17px;
  }
  .secondlayertext {
    text-align: center;
  }
  .semiColonImageCloudLandscape {
    height: max-content;
  }
  .img-top {
    height: 31px;
    position: absolute;
    left: 30px;
    top: 230px;
  }
  .log-top {
    height: 60px;
    position: absolute;
    left: 93px;
    bottom: 99px;
  }
  .titile {
    position: absolute;
    left: 181px;
    bottom: 117px;
  }
  .bulb {
    margin-top: 50%;
    width: 200px;
    @include mediaMax(760px) {
      display: none;
    }
  }
  .bulb-top {
    color: $black;
    text-align: center;
    padding: 10px;
  }
  .color-combo {
    background-color: $cxoDasboardBlue;
  }
  .large-font-size {
    padding: 33px;
  }
  .thirdLayer {
    background-color: $cxoLightBlue;
  }
  .serviceInsightsTableAndGraphWrapper {
    max-width: 25rem;
  }
  .serviceInsightsWrapper {
    display: flex;
    flex-direction: row;
    &_graphWrapper {
      width: 50%;
    }
    @include mediaMax(992px) {
      flex-direction: column !important;
      &_graphWrapper {
        width: 100%;
      }
    }
  }
  .serviceInsightsTableWrapper {
    max-height: 15rem;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $white;
    }
  }
  .vertical-line {
    width: 2px;
    height: 100%;
    background-color: $black;
    margin-left: 42px;
  }
  .thirdLayerr {
    text-align: center;
  }
  .coin-top {
    width: 100%;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    display: flex;
  }
  .coin,
  .squareIcon {
    height: 5rem;
  }
  .divider {
    border-right: solid $black 1px;
    @include mediaMax(760px) {
      border-right: none;
    }
  }
  .square-top {
    width: 100%;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    display: flex;
  }
  .thirdpart {
    font-size: 18px;
  }
  .live-nation {
    display: inline-block;
    padding: 10px;
    font-size: 24px;
    font-weight: bold;
    border: 2px solid $white;
    margin: 47px;
  }
  .live {
    display: flex;
  }
  .live-nat {
    position: absolute;
    left: 249px;
    bottom: 112px;
  }
  .learn-more1 {
    display: flex;
    padding: 10px 20px;
    color: $white;
    text-decoration: none;
    font-weight: bold;
    border: none;
    border-radius: 5px;
  }
  .forthlayer {
    background-color: darken($color: $cxoDashboardOrange, $amount: 2);
  }
  .piclayer1 {
    display: flex;
    letter-spacing: 1px;
    justify-content: center;
    right: 25px;
  }
  .piclayer12 {
    margin: 15px;
    margin-left: 15%;
    @include mediaMax(990px) {
      margin: 0px;
      position: static;
    }
  }
  .media-fire {
    position: relative;
    top: 33px;
    left: 57px;
    height: 60%;
    padding-right: 40px;
  }
  .serviceInsightsImg {
    width: 6rem;
    display: block;

    @include mediaMax(990px) {
      width: 4rem;
      margin-right: 2rem;
    }
  }
  .line {
    border: 1px solid $black;
    position: absolute;
    z-index: 9999;
    width: 426px;
    right: 0px;
  }
  .line1 {
    border: 1px solid $black;
    position: absolute;
    z-index: 9999;
    width: 428px;
    right: -22px;
  }
  .fontchange {
    font-size: 50px;
    color: darken($color: $cxoDashboardOrange, $amount: 2);
    text-align: center;
  }
  .fontchange1 {
    font-size: 50px;
    color: $cxoOrange;
    text-align: center;
  }
  .players {
    height: 180px;
    width: 132px;
  }
  .height-font {
    font-size: 1.5rem;
    padding-bottom: 5rem;
    font-weight: bold;
  }
  .height-font-1 {
    font-size: 1.5rem;
    padding-bottom: 10%;
    font-weight: bold;
  }
  .fifthlayer {
    background-color: $cxoOrange;
  }
  .button-new-blue {
    position: relative;
    top: 222px;
  }
  .button-new-green {
    position: relative;
    top: 70px;
  }
  .next-line {
    height: 570px;
  }
  .glowing-button {
    background-color: $skyBlue;
    color: $white;
    border: 0px;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 20px;
    transition: box-shadow 0.3s;
  }
  .glowing-button:hover {
    box-shadow: 5px 3px 15px 3px rgba(133, 180, 208, 0.7);
  }

  .layer-adjus {
    max-width: 171px;
  }
  .first-layer-blue {
    font-size: 40px;
  }
  .first-layer-text {
    font-size: 20px;
  }
  .first-layer-sentence {
    font-size: 20px;
  }
  .first-layer-image1 {
    padding: 2rem;
  }
  .img-top3 {
    height: max-content;
  }
  .slide-effect {
    animation: slideAnimation 1s ease-in-out infinite alternate;
  }

  .sixthlayer {
    background-color: $cxoDasboardBlue;
  }

  .popup-new {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  .popup-content {
    position: relative;
    background-color: $white;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  .close-popup {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
  }
  .popup-content h1 {
    font-size: 36px;
    margin-bottom: 10px;
  }
  .popup-content p {
    font-size: 18px;
    color: $hdfbsCardcolor;
  }
  .font_display {
    font-size: 28px;
    color: $skyBlue;
  }
  @keyframes slideAnimation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  @media (max-width: 767px) {
    .next-line1 {
      display: none;
    }
    .vertical-line .line {
      display: none;
    }
    .line {
      display: none;
    }
    .line1 {
      display: none;
    }
  }
  .fifthLayerLabel {
    font-size: 18px;
    color: $black;
  }
  .auditHeadTxt {
    font-size: 60px;
    color: $cxoOrange;
  }
  .adjust-media {
    padding-bottom: 2rem;
  }
  @media (max-width: 575px) {
    .hdfbsDashboard-heading {
      font-size: 24px;
    }
    .hdfbsDashboard-paragraph {
      font-size: 20px;
    }
    .hdfbsCard {
      height: 150px;
    }
    .hdfbsCard-img-top {
      height: 60px;
    }
    .hdfbsCard-title {
      font-size: 20px;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    .hdfbsDashboard-heading {
      font-size: 30px;
    }
    .hdfbsDashboard-paragraph {
      font-size: 24px;
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .hdfbsDashboard-heading {
      font-size: 30px;
    }
    .hdfbsDashboard-paragraph {
      font-size: 22px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .hdfbsDashboard-heading {
      font-size: 30px;
    }
    .hdfbsDashboard-paragraph {
      font-size: 22px;
    }
  }
  @media (min-width: 1200px) {
    .hdfbsDashboard {
      padding: 30px;
    }
    .hdfbsDashboard-heading {
      font-size: 30px;
    }
    .hdfbsDashboard-paragraph {
      font-size: 22px;
      padding: 1px;
    }
  }
  .btn-secondary12 {
    height: 36px;
    float: right;
    font-size: 40px;
  }
  #severityFilter {
    height: 25px;
    width: 93px;
    border-radius: 20px;
    margin-left: 27px;
  }
  #report > .dropdown-toggle::after {
    margin-left: 1rem !important;
    display: none;
    text-decoration: none;
  }
  .second-font1234 {
    font-size: 22px;
    font-weight: bold;
    position: relative;
    top: 8px;
  }
  .form-select1 {
    border-radius: 20px;
    border: 1px solid $black;
    margin-left: 24px;
    width: 188px;
    position: relative;
    top: 23px;
    padding: 9px;
  }
  .newContainer {
    margin-right: 18px;
    padding-bottom: 11px;
  }
  #report > .btn:focus {
    outline: none;
    box-shadow: none;
  }
  #report > button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }
  .third-count {
    width: 77%;
  }
  #carsssss {
    position: relative;
    top: 14px;
    left: 18px;
  }
  .new-success {
    position: absolute;
    width: 181px;
    height: 162px;
    right: -29px;
    top: 69px;
  }
  .load-data {
    margin-left: 450px;
  }
  #cars {
    padding: 5px;
    border-radius: 20px;
    margin-left: 16px;
  }
  .toggleBtnCxo {
    margin: 10px;
    position: sticky;
    top: 10px;
    background-color: $white !important;
    border: solid $black 1px !important;
    &_toggleIcon {
      transform: rotate(180deg);
    }
  }
  .serviceInsight li {
    font-size: 18px;
    margin-top: 10px;
  }
  .serviceInsight_table {
    overflow-x: scroll;
  }
  @media (min-width: 250px) and (max-width: 650px) {
    .RightCardParent {
      min-height: 30%;
      max-height: 40%;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .LeftCardParent {
      height: 600px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  @media (min-width: 780px) and (max-width: 992px) {
    .RightCardParent {
      min-height: 60%;
      max-height: 80%;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
    }
    .LeftCardParent {
      height: 700px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }

  .InsightTextData {
    height: 100%;
    max-height: 624px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $white;
    }
  }
  .myLineChart {
    width: 100%;
    overflow-x: auto;
    margin-right: 1rem;
    min-height: 400px;
    height: 100%;
    max-height: 400px;
  }
  .myBarChart {
    width: 100%;
    margin-right: 1rem;
    min-height: 200px;
    height: 100%;
    max-height: 200px;
  }
  .serviceBarGraph {
    width: 100%;
    min-height: 400px;
    padding-bottom: 20px;
  }
}

.serviceInsight li{
  font-size: 18px;
  margin-top: 10px;
}

.serviceInsight_table{
  overflow-x: scroll;
}
