@import "../color.scss";

.accordionBody {
  position: relative;
  &_tableWrapper {
    width: 80%;
  }
  &_shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;}
  &_allResourcesIcons {
    //height for image
    height: 18px;
    &_label {
      font-size: 0.6rem;
      font-weight: 700;
    }
  }
  & .nav-tabs {
    background-color: $tab_background;
    & .nav-link {
      &.active {
        border-top: 3px solid $header_border;
        border-right: 2px solid $disabled_label;
      }
    }
  }
  .titles {
    color: $header_border;
  }
  .subdata {
    font-size: 13px;
    padding: 10px;
  }
  .header {
    color: $blue;
    font-weight: 100;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  .subheader {
    color: $black;
    font-weight: 600;
    font-size: 18px;
  }
}
.accordion_style {
  .acc_title {
    font-size: 0.8rem;
    font-weight: 400;
    padding-right: 10px;
  }
  .acc_count {
    width: max-content;
    font-size: 0.8rem;
    font-weight: 600;
    color: $black;
    border: solid $blue 2px;
    background-color: $white;
    padding: 1px 3px 1px 3px;
    border-radius: 2px;
    span{
      
      color: $blue;
    }
  }
  .acc_img {
    padding-right: 10px;
  }
  .accordion-item {
    margin-bottom: 10px;
    border: 1px solid $tab_background;
  }
  .img_style {
    height: 20px;
  }
  .accordion-button.collapsed {
    padding: 8px !important;
  }
  .dropdown_style {
    border-color: transparent;
    background-color: transparent;
    padding: 0px;
  }
  .inventoryPage .dropdown {
    border: none;
  }
  .btn_space {
    margin-left: 72%;
  }
  .accordion-button:not(.collapsed) {
    color: $accordionDisabled;
    background-color: $white !important;
    box-shadow: 0 4px 8px 0 rgba(177, 174, 174, 0.2),
      0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .space_btween {
    width: 95%;
  }

  @media screen and (min-width: 0px) and (max-width: 426px) {
    .space_btween {
      width: 85%;
    }
  }
}
